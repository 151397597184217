<template>
  <v-container fluid class="container-page">
    <v-row>
      <v-col cols="12" md="12">
        <v-row>
          <v-col cols="12">
            <h2>Produtos</h2>
          </v-col>
          <v-col>
            <v-card>
              <v-card-title>
                <v-container fluid>
                  <v-row>
                    <v-col cols="12" md="6">
                      <div class="form-item">
                        <div class="form-group">
                        <input type="text" class="input-default" @keyup="serchProduct" v-model="term" placeholder="Busque seu produto">
                        </div>
                      </div>
                    </v-col>
                    <v-col>
                    <button class="button-default" @click="$router.push('/new-product')">Novo Produto</button>
                    </v-col>
                  </v-row>
                </v-container>
                
              </v-card-title>
              <v-card-text>
                <v-container fluid>
                  <v-row>
                    <v-col cols="6" md="3" v-for="p in productsFiltered" :key="p.div">
                      <v-card
                        class="card-product bg-gray-100"
                      >
                        <v-img
                          :src="p.showCaseProducts[0].image"
                          lazy-src="@/assets/no-image.png"
                          class="img"
                        ></v-img>

                        <v-icon class="icon-edit">mdi-pencil-outline</v-icon>


                        <v-card-text class="card-product-text text-gray-700 bg-gray-100" >
                          <h2 class="price" v-if="parseFloat(p.promotionalPrice) > 0">{{ p.promotionalPrice.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }}</h2>
                          <h2 class="price" v-if="!parseFloat(p.promotionalPrice) > 0">{{ p.sellPrice.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }}</h2>
                          <p class="name">{{ p.name }}</p>
                          <p class="sell">0 vendas</p>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                  
                </v-container>
                
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import config from '@/store/config'
const api = axios.create({
    baseURL: config.baseURL
})


export default {
    name: 'ProductsPage',
    metaInfo() {
      return {
        title: `Produtos - Beeasy INK - ${this.userData.fullName}`
      }
    },
    data () {
      return {
        term: '',
        autoRefreshProducts: false,
        userData: {},
        products: [],
        productsFiltered: []
      }
    },
    async mounted(){
      await this.init()
    },
    methods:{
      async init(){
        this.userData = this.$store.getters.getUserData
        await this.getProducts();
      },
      async getProducts(){
        try {
          let response = await api.get(`/products/store/${this.userData.idStore}`, await this.$store.getters.getConfig)
          this.products = response.data
          this.productsFiltered = response.data

          let productsWithoutImage = 0
          this.products.forEach(product => {
            product.showCaseProducts.forEach(showCaseProduct => {
              if (showCaseProduct.image === '') {
                productsWithoutImage++
              }
            })
          })
          this.autoRefreshProducts = productsWithoutImage > 0

          if(this.autoRefreshProducts){
            setTimeout(() => {
              this.getProducts()
            }, 2000);
          }
        } catch (error) {
          console.log(error);
        }
      },
      serchProduct(){
        this.productsFiltered = this.products.filter(p => p.name.toLowerCase().includes(this.term.toLowerCase()))
      }
    
    }
}
</script>

<style scoped>
  *{
    font-family: "Outfit", sans-serif;
  }

  .text-gray-700 {
      --tw-text-opacity: 1;
      color: rgb(55 65 81 / var(--tw-text-opacity));
  }

  .bg-gray-100 {
      --tw-bg-opacity: 1;
      background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  }
  .input-default{
    width: 100%;
    padding: .5rem;
    color: #404049;
    font-size: 1rem;
    font-weight: 400;
    border-radius: 5px;
    border: 1px solid rgba(64, 64, 73, .3);

  }

  .button-default{
    width: 100%;
    padding: .5rem;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 5px;
    background-color: #f70293;
    border: 1px solid #f70293;
    cursor: pointer;
  }

  .container-page{
    width: 84%;
  }

  @media screen and (max-width: 600px){
    .container-page{
      width: 100% !important;
      padding-right: 12px;
    }
  }

  .card-product{
    box-shadow: none !important;
    border: 1px solid;
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity));
  }

  .price{
    font-weight: 400;
    font-size: 1.1rem;
  }

  .card-product .card-product-text {
    padding: .4rem;
  }

  .card-product .card-product-text .name{
    margin-top: 1rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
  }

  .card-product .card-product-text .sell{
    font-size: .8rem;
    margin: 0;
    font-weight: 400;
  }

  .card-product .img{
    position: relative;
  }

  .card-product .icon-edit{
    position: absolute;
    font-size: 1.2rem;
    top: 5px;
    right: 5px;
    color: #f70293;
    cursor: pointer;
  }
</style>