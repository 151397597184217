<template>
  <v-container class="container-page">
    <v-row class="pr-4">
      <v-col cols="12">
        <v-row>
          <v-col cols="12" md="6">
            <CardInfo :userData="userData" :storeData="storeData" icon="Carteira.svg" title="Valor à Liberar"
              :value="toRelease.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })" />
          </v-col>
          <v-col cols="12" md="6">
            <CardInfo :userData="userData" :storeData="storeData" icon="Carteira.svg" title="Valor Disponível"
              :value="balance.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })" />
          </v-col>

        </v-row>

        <v-col cols="12">
          <v-card>
            <v-card-title>
              <h3 class="text-gray-700">Histórico de Entradas e Saídas</h3>
              <v-spacer></v-spacer>
              <v-btn color="#00AF7D" outlined @click="openWithdrawRequestDialog()">
                Solicitação de Saque
              </v-btn>
            </v-card-title>
            <v-card-text style="height: 70vh; overflow-y: scroll;">
              <v-timeline align-top dense>
                <v-timeline-item v-for="transaction in transactionHistory" :key="transaction.id"
                  :color="transaction.type === 'C' ? 'green' : 'red'" small>
                  <v-row class="pt-1">
                    <v-col cols="12" md="1">
                      <strong>{{ formatDate(transaction.createdAt) }}</strong>
                    </v-col>
                    <v-col cols="12" md="2">
                      <strong>{{ transaction.description }}</strong>
                    </v-col>
                    <v-col cols="12" md="1">
                      <strong :class="{ 'text-red': transaction.type !== 'C' }">
                        {{ formatCurrency(transaction.type === 'C' ? transaction.amount : -transaction.amount) }}
                      </strong>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-btn color="#00AF7D" :style="!$store.getters.isMobile ? 'float: right;' : 'float: left;'" text
                        v-if="transaction.type === 'C' && transaction.order !== null"
                        @click="openDetailsDialog(transaction)">
                        Detalhes
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>
          </v-card>
          <TransactionDetailsDialog :detailsDialog="detailsDialog" :selectedTransaction="selectedTransaction"
            @close-dialog="detailsDialog = false" />
          <!-- Diálogo para exibir as solicitações de saque -->
          <v-dialog v-model="dialog" max-width="700" content-class="withdraw">
            <v-card>
              <v-card-title>
                <span class="headline text-gray-700">Solicitações de Saque</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="dialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text style="overflow: auto;height: 50vh">
                <v-container>
                  <v-row>
                    <v-col cols="12" md="8">
                      <input type="number" class="input-default" v-model="withdrawValue"
                        placeholder="Use ponto (.) como separador decimal" />
                      <small style="color: red">
                        *O valor solicitado será enviado para o pix CPF
                        <strong>{{ storeData.cpf }}</strong>


                      </small>
                    </v-col>
                    <v-col>
                      <button class="button-default" @click="sendWithdrawRequest()">Solicitar Saque</button>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      Saques realizado até as 12h são processados no mesmo dia, após esse horário serão processados no
                      próximo dia útil.
                    </v-col>
                  </v-row>
                </v-container>
                <v-timeline align-top dense>
                  <v-timeline-item v-for="request in withdrawRequests" :key="request.id"
                    :color="getStatusColor(request.status)" small>
                    <v-row class="pt-1">
                      <v-col cols="12" md="3">
                        <strong>Data: {{ formatDate(request.createdAt) }}</strong>
                      </v-col>
                      <v-col cols="12" md="3">
                        <strong>Status: {{ translateStatus(request.status) }}</strong>
                      </v-col>
                      <v-col cols="12" md="3">
                        <strong>Valor: {{ formatCurrency(request.amount) }}</strong>
                      </v-col>
                    </v-row>
                  </v-timeline-item>
                </v-timeline>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
      </v-col>
    </v-row>
    <Loading v-if="loading" />
  </v-container>
</template>

<script>
import axios from 'axios';
import config from '@/store/config';
import TransactionDetailsDialog from '@/components/Wallet/TransactionDetailsDialog.vue';
import CardInfo from '@/components/CardInfo.vue';
import Loading from '@/components/Loading.vue';


// Criação da instância do axios com a baseURL configurada
const api = axios.create({
  baseURL: config.baseURL
});

export default {
  name: 'Wallet',
  components: {
    CardInfo,
    TransactionDetailsDialog,
    Loading
  },
  metaInfo() {
    return {
      title: `Carteira - Beeasy INK - ${this.userData.fullName}`
    };
  },
  data() {
    return {
      loading: false,
      balance: 0,
      toRelease: 0,
      dialog: false,
      detailsDialog: false,
      userData: {},
      storeData: {},
      transactionHistory: [],
      withdrawRequests: [],
      selectedTransaction: {},
      withdrawValue: null
    };
  },
  async mounted() {
    this.loading = true;
    await this.init();
    await this.fetchWalletInfo();
    this.loading = false;
    this.$store.commit('insertLog',{ action: 'Navegacao',  description: `[${this.userData.fullName}] acessou a página de carteira`})
  },
  methods: {
    async init() {
      this.userData = this.$store.getters.getUserData;
      this.storeData = this.$store.getters.getStoreData;
    },
    async fetchWalletInfo() {
      try {
        let config = await this.$store.getters.getConfig;
        let response = await api.get('/wallet', config);
        console.log('API Response:', response.data);
        this.balance = response.data.balance;
        this.toRelease = response.data.toRelease || 0;
        this.transactionHistory = response.data.history;
        this.withdrawRequests = response.data.withdrawRequests || [];
      } catch (error) {
        console.log(error);
      }
    },
    async sendWithdrawRequest() {
      if (this.withdrawValue === null || this.withdrawValue === '') {
        this.$toast.error('Informe um valor para solicitar o saque');
        return;
      }

      let pendingRequest = this.withdrawRequests.find(request => request.status === 'pending');
      if (pendingRequest) {
        this.$toast.error('Você já possui uma solicitação de saque pendente. Aguarde a análise da equipe financeira.');
        return;
      }
      try {
        let config = await this.$store.getters.getConfig;
        let response = await api.post('/wallet/withdraw', { value: parseFloat(this.withdrawValue) }, config);
        console.log('API Response:', response.data);
        this.$store.commit('insertLog',{ action: 'Acao',  description: `[${this.userData.fullName}] solicitou saque no valor de ${this.withdrawValue}`})
        this.$toast.success('Solicitação de saque enviada com sucesso');
        this.withdrawValue = null;
        await this.fetchWalletInfo();
      } catch (error) {
        console.log(error);
        if (error.response.data.error === "Insufficient balance") {
          this.$toast.error('Saldo insuficiente para realizar a solicitação de saque');
        } else {
          this.$toast.error('Erro ao enviar a solicitação de saque');
        }
      }
    },
    translateStatus(status) {
      switch (status) {
        case 'pending':
          return 'Pendente';
        case 'approved':
          return 'Aprovado';
        case 'refused':
          return 'Recusado';
        default:
          return status;
      }
    },
    getStatusColor(status) {
      switch (status) {
        case 'pending':
          return 'orange';
        case 'approved':
          return 'green';
        case 'refused':
          return 'red';
        default:
          return 'grey'; // Cor padrão se não for um dos três casos
      }
    },
    formatCurrency(value) {
      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString('pt-BR');
    },
    openDetailsDialog(transaction) {
      this.selectedTransaction = transaction.order;
      this.detailsDialog = true;
      this.$store.commit('insertLog',{ action: 'Acao',  description: `[${this.userData.fullName}] clicou em detalhes da transação {${transaction.id}}`})
    },
    openWithdrawRequestDialog() {
      this.dialog = true;
      this.$store.commit('insertLog',{ action: 'Acao',  description: `[${this.userData.fullName}] clicou em solicitar saque`})
    }
  }
};
</script>

<style scoped>
/* Estilo base para toda a aplicação */
* {
  font-family: "Outfit", sans-serif;
}

h3 {
  font-size: 1.17rem;
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.input-default {
  width: 100%;
  padding: .5rem;
  color: #404049;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 5px;
  border: 1px solid rgba(64, 64, 73, .3);
}

.button-default {
  width: 100%;
  padding: .5rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 5px;
  background-color: #00AF7D;
  border: 1px solid #00AF7D;
  cursor: pointer;
}

/* Estilo para o carregamento (animação de pontos) */
.dots-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.dot {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: #b3d4fc;
  animation: pulse 1.5s infinite ease-in-out;
}

.dot:last-child {
  margin-right: 0;
}

.dot:nth-child(1) {
  animation-delay: -0.3s;
}

.dot:nth-child(2) {
  animation-delay: -0.1s;
}

.dot:nth-child(3) {
  animation-delay: 0.1s;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    background-color: #b3d4fc;
    box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
  }

  50% {
    transform: scale(1.2);
    background-color: #6793fb;
    box-shadow: 0 0 0 10px rgba(178, 212, 252, 0);
  }

  100% {
    transform: scale(0.8);
    background-color: #b3d4fc;
    box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
  }
}

/* Estilo para os cartões */
.card {
  --primary-clr: #00AF7D;
  --dot-clr: #fff201;
  --play: hsl(69, 100%, 50%);
  width: 100%;
  height: 170px;
  border-radius: 10px;
}

.card {
  font-family: "Arial";
  color: rgb(255, 255, 255);
  display: grid;
  cursor: pointer;
  grid-template-rows: 50px 1fr;
}

.card:hover .img-section {
  transform: translateY(1em);
}

.card-desc {
  border-radius: 10px;
  padding: 15px;
  position: relative;
  top: -10px;
  display: grid;
  gap: 10px;
  background: var(--primary-clr);
}

.card-time {
  font-size: 1.6em;
  font-weight: 600;
}

.img-section {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(0);
  transition: transform 0.5s ease-in;
  font-size: 1.2em;
  background: var(--primary-clr);
  border-radius: 10px;
}

.card-header {
  display: flex;
  justify-content: space-between;
}

.card-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-title {
  font-size: 1.1em;
  font-weight: 700;
}

.dot2 {
  width: 7px;
  height: 7px;
  margin: 0 2px;
  border-radius: 50%;
  background: var(--dot-clr);
}

.recent {
  font-size: 0.9em;
}

.card.play {
  --primary-clr: var(--play);
  --dot-clr: #fff;
}

.yellow-card .img-section {
  background-color: #FFD700;
}

/* Estilo para o diálogo de detalhes da transação */
.details-dialog {
  border-radius: 8px;
}

.details-card {
  padding: 16px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.details-card .v-card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0;
}

.details-card .v-card-text {
  padding-top: 0;
}

.details-card .headline {
  font-weight: bold;
  font-size: 1.25rem;
}

/* Estilo personalizado para o botão */
.v-btn {
  color: #e91e63;
}

.v-btn:hover {
  background-color: #f8bbd0;
}

.v-divider {
  margin: 16px 0;
}

.withdraw {
  .custom-dialog {
    z-index: 1050 !important;
  }
}

/* Estilo para texto em vermelho */
.text-red {
  color: red;
}
</style>
