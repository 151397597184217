<template>
  <v-container fluid class="container-page">
    <v-row>
      <v-col cols="12" md="12">
        <v-row>
          <v-row>
            <v-breadcrumbs :items="items">
              <template v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :disabled="item.disabled"
                  :href="'#'"
                  @click.prevent="$router.push(`/${item.href}`)"
                >
                  {{ item.text }}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </v-row>
          <v-col cols="12">
            
            <h2>{{ this.model.description }} - {{  this.model.costPrice.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}</h2>
          </v-col>
          <v-col>
            <v-card>
              <v-card-title>
                <v-container fluid>
                  <v-row>
                    <v-col cols="12" md="4">
                      <button class="button-default" @click="mNovoMockup = true">Novo Mockup</button>
                    </v-col>
                    <v-col>
                      
                    </v-col>
                  </v-row>
                </v-container>
                
              </v-card-title>
              <v-card-text>
                <img src="@/assets/loading.gif" class="loading" alt="" v-if="loading">
                <v-container fluid v-if="!loading">
                  <v-row>
                    <v-col cols="12" md="3" v-for="m in mockups" :key="m.id">
                      <v-card
                        class="card-product bg-gray-100"
                      >
                        <v-img
                          :src="m.image"
                          class="img"
                        ></v-img>

                        <v-icon class="icon-edit">mdi-pencil-outline</v-icon>


                        <v-card-text class="card-product-text text-gray-700 bg-gray-100" >
                          <h2 class="price">{{ m.gender.name }}</h2>
                          <p class="sell">
                            {{ m.color.name }}
                          </p>
                          <div class="sizes">
                            <v-btn
                              color="#fff"
                              class="mr-2"
                              v-for="s in JSON.parse(m.sizes)"
                              :key="s"
                              fab
                              x-small
                              
                            >
                              {{ s }}
                            </v-btn>
                          </div>
                          
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                  
                </v-container>
                
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>



    <!--  MODAIS -->
    <v-dialog
      v-model="mNovoMockup"
      persistent
      style="z-index: 9999"
      max-width="900"
    >
      <v-card>
        <v-card-title class="text-h5">
          Novo Mockup
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <label for="">Mockup com Grade</label>
                <img :src="insertData.image" alt="" style="width: 100%;" @click="$refs.image.click()">

                <input label="Foto" type="file" ref="image" @change="getFile" />
              </v-col>
              <v-col cols="12" md="6">
                <label for="">Mockup sem Grade</label>
                  <img :src="insertData.image2" alt="" style="width: 100%;" @click="$refs.image2.click()">
                  <input label="Foto" type="file" ref="image2" @change="getFile2"/>
              </v-col>
            </v-row>
            <v-row>
                <v-col>
                  <label for="color">Cor</label>
                  <select name="" id="color" class="input-default" v-model="insertData.id_color">
                    <option v-for="c in colors" :key="c.id" :value="c.id" :style="`color: ${c.hex}`"> {{ c.name }}</option>
                  </select>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                  <label for="gender">Gênero</label>
                  <select name="" id="gender" class="input-default" v-model="insertData.id_gender">
                    <option v-for="g in genders" :key="g.id" :value="g.id"> {{ g.name }}</option>
                  </select>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                  <label for="sizes">Tamanhos</label>
                  <select name="" id="sizes" class="input-default" multiple v-model="insertData.sizes">
                    <option value="P">P</option>
                    <option value="M">M</option>
                    <option value="G">G</option>
                    <option value="GG">GG</option>
                  </select>
                </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="#f70293"
            text
            @click="mNovoMockup = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="#f70293"
            text
            @click="insertMockup"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    
    <div class="fill" v-if="loadingInsert">
      <div class="loading-modal">
        <img src="@/assets/loading.gif" class="loading-img" alt="">
      </div>
    </div>
  </v-container>
</template>

<script>
import axios from 'axios'
import config from '@/store/config'

const api = axios.create({
    baseURL: config.baseURL
})


export default {
    name: 'MockupsPage',
    metaInfo() {
        return {
          title: `Mockups - Beeasy INK - ${this.userData.fullName}`
        }
    },
    data () {
      return {
        mNovoMockup: false,
        loadingInsert: false,
        loading: false,
        term: '',
        insertData: {
          image: 'https://cdn-icons-png.flaticon.com/512/3616/3616929.png',
          image2: 'https://cdn-icons-png.flaticon.com/512/3616/3616929.png',
          id_color: 0,
          id_gender: 0,
          sizes: []
        },
        model: {},
        colors: [],
        genders: [],
        mockups: [
        ],
        items: [
        {
          text: 'Modelos',
          disabled: false,
          href: 'models',
        },
        {
          text: 'Mockups',
          disabled: false,
          href: '#',
        }
      ],
      }
    },
    async mounted(){
      await this.init()
    },
    methods:{
      async init(){
        this.loading = true
        let role = this.$store.getters.getUserData.role
        if(role !== 'admin'){
          this.$router.push('/home')
        }
        await this.getModel();
        await this.gerColors();
        await this.getGenders();
        this.loading = false
      },
      async gerColors(){
        try {
          let response = await api.get('/colors', await this.$store.getters.getConfig)
          this.colors = response.data
        } catch (error) {
          console.log(error)
        }
      },
      async getGenders(){
        try {
          let response = await api.get('/genders', await this.$store.getters.getConfig)
          this.genders = response.data
        } catch (error) {
          console.log(error)
        }
      },
      async getModel(){
        try {
          let response = await api.get(`/models/${this.$route.params.id}`, await this.$store.getters.getConfig)
          this.model = response.data
          this.mockups = response.data.mockups
        } catch (error) {
          console.log(error)
        }
      },
      async getFile(e){
        let file = e.target.files[0];
        let app = this
        let reader = new FileReader();
        reader.onloadend = function() {
          app.insertData.image = reader.result
        }
        if (file) {
            reader.readAsDataURL(file);
        }
      },
      async getFile2(e){
        let file = e.target.files[0];
        let app = this
        let reader = new FileReader();
        reader.onloadend = function() {
          app.insertData.image2 = reader.result
        }
        if (file) {
            reader.readAsDataURL(file);
        }
      },
      async insertMockup(){
        try {
          this.loadingInsert = true
          let insert = {...this.insertData}
          insert.id_model = this.model.id
          insert.sizes = JSON.stringify(insert.sizes)
          let response = await api.post(`/mockups`, insert, await this.$store.getters.getConfig)
          this.mockups.push(response.data)
          this.mNovoMockup = false
          this.insertData = {
            image: 'https://cdn-icons-png.flaticon.com/512/3616/3616929.png',
            image2: 'https://cdn-icons-png.flaticon.com/512/3616/3616929.png',
            id_color: 0,
            id_gender: 0,
            sizes: []
          }
          this.loadingInsert = false
        } catch (error) {
          console.log(error)
        }
      }
    
    }
}
</script>

<style scoped>
  *{
    font-family: "Outfit", sans-serif;
  }

  .text-gray-700 {
      --tw-text-opacity: 1;
      color: rgb(55 65 81 / var(--tw-text-opacity));
  }

  .bg-gray-100 {
      --tw-bg-opacity: 1;
      background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  }
  .input-default{
    width: 100%;
    padding: .5rem;
    color: #404049;
    font-size: 1rem;
    font-weight: 400;
    border-radius: 5px;
    border: 1px solid rgba(64, 64, 73, .3);

  }

  .button-default{
    width: 100%;
    padding: .5rem;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 5px;
    background-color: #f70293;
    border: 1px solid #f70293;
    cursor: pointer;
  }

  .container-page{
    width: 84%;
  }

  .loading{
    display: block;
    margin: 0 auto;
    width: 50%;
  }

  @media screen and (max-width: 600px){
    .container-page{
      width: 100% !important;
      padding-right: 12px;
    }
    .loading{
      width: 100%;
    }
  }

  .card-product{
    box-shadow: none !important;
    border: 1px solid;
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity));
  }

  .price{
    font-weight: 400;
    font-size: 1.1rem;
  }

  .card-product .card-product-text {
    padding: .4rem;
  }

  .card-product .card-product-text .name{
    margin-top: 1rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
  }

  .card-product .card-product-text .sell{
    font-size: 1.1rem;
    margin: 0;
    font-weight: 400;
    margin-top: 1rem;
  }

  .card-product .img{
    position: relative;
  }

  .card-product .icon-edit{
    position: absolute;
    font-size: 1.2rem;
    top: 5px;
    right: 5px;
    color: #f70293;
    cursor: pointer;
  }

  .sizes{
    margin-top: 1rem;
  }


  .fill{
    background-color: #0000008f;
    z-index: 99999999;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 150vh;
  }

  .loading-modal img{
    display: block;
    margin: 0 auto;
    margin-top: 22vh;
    width: 50%;
  }

</style>