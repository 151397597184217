<template>
  <v-container fluid class="container-page">
    <v-row>
      <v-col cols="12" md="12">
        <v-row>
          <v-col cols="12">
            <h2>Novo Produto</h2>
          </v-col>
          <v-col>
            <!-- CARD SELEÇÃO MODELO -->
            <v-card v-if="modelSelected == null">
              <v-card-title>
                <h2>
                  Selecione o Modelo
                </h2>
              </v-card-title>
              <v-card-text>
                <img src="@/assets/loading.gif" class="loading" alt="" v-if="loading">
                <v-container fluid v-if="!loading">
                  <v-row>
                    <v-col cols="12" md="3" v-for="m in models" :key="m.id" @click="selectModel(m)" style="cursor: pointer;">
                      <v-card
                        class="card-product bg-gray-100"
                      > 
                        <v-img
                          :src="m.mockups != '' ? m.mockups[0].image2 : '@/assets/no-image.png'"
                          lazy-src="@/assets/no-image.png"
                          class="img"
                        ></v-img>


                        <v-card-text class="card-product-text text-gray-700 bg-gray-100" >
                          <h2 class="price">{{ m.description }}</h2>
                          
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                  
                </v-container>
                
              </v-card-text>
            </v-card>

            <!-- CARD PERSONALIZAR -->
            <v-card v-if="modelSelected != null">
              <v-card-title>
                <v-container>
                  <v-row>
                    <v-col>
                      <h3>Adicionar Estampa</h3>
                      <p class="text-gray-700">Redimensione sua estampa nas dimensões corretas do grid de impressão da camiseta.</p>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-container>
                        <v-row>
                          <v-col>
                            <h2>Carregar Imagem</h2>
                            <h3>A imagem deve ter:</h3>
                            <p>Tamanho máximo de 20MB
                            <br>Formato PNG</p>
                            <button class="button-default" @click="$refs.image.click()">Adicionar Imagem</button>
                            <input label="Foto" type="file" ref="image" @change="getFile2" style="opacity: 0;position: absolute;left: -100rem;"/><br>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-card outlined v-if="estampa">
                              <v-card-text>
                                <v-row>
                                  <v-col>
                                    <img :src="estampa" style="width: 80%;" alt="">
                                  </v-col>
                                  <v-col>
                                    <h3>{{ estampaName }}</h3>
                                  </v-col>
                                  <v-col style="text-align: right;">
                                    <v-icon style="cursor: pointer;" @click="removeEstampa">
                                      mdi-delete
                                    </v-icon>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <h2>Selecione a Cor</h2>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <h4>Feminino</h4>
                            <v-btn
                            class="mr-2"
                            v-for="m in mockupsFem"
                            :key="m.id"
                            :color="m.color.hex"
                            fab
                            x-small
                            @click="selectColor(m)"
                            :class="{ 'color-selected': canvasPreviews.find(x => x.id == m.id) ? true : false}"
                            >
                              <v-icon v-if="canvasPreviews.find(x => x.id == m.id) != undefined">
                                mdi-check
                              </v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <h4>Masculino</h4>
                            <v-btn
                              class="mr-2"
                              v-for="m in mockupsMas"
                              :key="m.id"
                              :color="m.color.hex"
                              fab
                              x-small
                              @click="selectColor(m)"
                              :class="{ 'color-selected': canvasPreviews.find(x => x.id == m.id) ? true : false}"
                            >
                              <v-icon v-if="canvasPreviews.find(x => x.id == m.id) != undefined">
                                mdi-check
                              </v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>
                    <v-col>
                      <div class="card-canva" style="height: 520px;max-height: 520px;">
                        <tui-image-editor ref="editor" :include-ui="useDefaultUI" :options="options" @objectMoved="onObjectScaled" @objectScaled="onObjectScaled" @objectRotated="onObjectScaled"></tui-image-editor>
                      </div>
                      <div class="gender-btns">
                        <button :class="{ 'button-select': true, 'center': true, 'active': genderEditorSelected == 1 ? true : false}" @click="selectGenderEditor(1)">Masculino</button>
                        <button :class="{ 'button-select': true, 'center': true, 'active': genderEditorSelected == 2 ? true : false}"  @click="selectGenderEditor(2)">Feminino</button>
                      </div>
                    </v-col>
                    <v-col cols="1" class="cores">
                      <ul>
                        <li v-for="c in colorsShow" :key="c.id">
                          <div :class="{ 'cor': true, 'active': colorEditorSelected === c.idColor ? true : false}" :style="`background-color: ${c.color.hex}`" @click="selectColorEditor(c)"></div>
                        </li>
                      </ul>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              
            </v-card>

            <!-- CARD RESULTADO -->
            <v-card class="card-result" v-if="modelSelected != null">
              <v-container>
                <v-row>
                  <v-col cols="12" v-if="canvasPreviews.filter(x => x.idGender == 1) != ''">
                    <h3>Masculino</h3>
                  </v-col>
                  <v-col cols="3" v-for="c of canvasPreviews.filter(x => x.idGender == 1)" :key="`${c.id}${c.idGender}`">
                    <img src="@/assets/loading.gif" class="loading-preview" alt="" v-if="loadingPreview">
                    <div class="card-canva" :style="`width: 200px;height: 205px;opacity: ${loadingPreview == false ? 1 : 0}`">
                      <tui-image-editor :ref="`canva${c.id}${c.idGender}${c.idColor}`" :include-ui="useDefaultUI" :options="options"></tui-image-editor>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" v-if="canvasPreviews.filter(x => x.idGender == 2) != ''">
                    <h3>Feminino</h3>
                  </v-col>
                  <v-col cols="3" v-for="c of canvasPreviews.filter(x => x.idGender == 2)" :key="`${c.id}${c.idGender}`">
                    <img src="@/assets/loading.gif" class="loading-preview" alt="" v-if="loadingPreview">
                    <div class="card-canva" :style="`width: 200px;height: 205px;opacity: ${loadingPreview == false ? 1 : 0}`">
                      <tui-image-editor :ref="`canva${c.id}${c.idGender}${c.idColor}`" :include-ui="useDefaultUI" :options="options"></tui-image-editor>
                    </div>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <h2>Informações Gerais</h2>
                  </v-col>
                  <v-col cols="12">
                    <div class="form-item">
                      <div class="form-group">
                        <label for="name">Nome do Produto*</label>
                        <input type="text" id="name" class="input-default bg-gray-50 border-gray-200" v-model="formData.name" placeholder="">
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12">
                    <div class="form-item">
                      <div class="form-group">
                        <label for="name">Descrição*</label>
                        <textarea type="text" id="name" class="input-default bg-gray-50 border-gray-200" v-model="formData.description" placeholder="Aqui você tem a chance de mostrar, em um parágrafo, a essência da sua loja e seus produtos."></textarea>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6" offset-md="3" sm="6" offset-sm="3">
                    <v-container  class="calc">
                      <v-row>
                        <v-col cols="3" style="text-align: center;">
                          <h4 v-if="formData.promotionalPrice > 0">{{ parseFloat(formData.promotionalPrice).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }}</h4>
                          <h4 v-else>{{ parseFloat(formData.sellPrice).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }}</h4>
                          <small>Valor de venda</small>
                        </v-col>
                        <v-col cols="1"  style="text-align: center;">
                          -
                        </v-col>
                        <v-col cols="3" style="text-align: center;">
                          <h4>{{ modelSelected.costPrice.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }}</h4>
                          <small>custo do produto</small>
                        </v-col>
                        <v-col cols="1" style="text-align: center;">
                          =
                        </v-col>
                        <v-col cols="3" style="text-align: center;">
                          <h4 v-if="formData.promotionalPrice > 0">{{ (formData.promotionalPrice - modelSelected.costPrice).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }}</h4>
                          <h4 v-else>{{ (formData.sellPrice - modelSelected.costPrice).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }}</h4>
                          <small>lucro da venda</small>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                  <v-col cols="12">
                    <div class="form-item">
                      <div class="form-group">
                        <label for="price">Valor do Produto*</label>
                        <input type="number" id="price" v-model="formData.sellPrice" class="input-default bg-gray-50 border-gray-200" :placeholder="`Sugerimos ${(modelSelected.costPrice * 2).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}`">
                        <span class="txt-error">{{textError}}</span>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <div class="form-item">
                      <div class="form-group">
                        <label for="promotionalPrice">Valor Promocional*</label>
                        <input type="number" id="promotionalPrice" v-model="formData.promotionalPrice" class="input-default bg-gray-50 border-gray-200" :placeholder="`Sugerimos ${((modelSelected.costPrice * 2) - modelSelected.costPrice*0.25).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}`">
                        <span class="txt-error">{{textError}}</span>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="4" offset-md="4">
                    <div class="form-item">
                      <div class="form-group">
                        <button class="button-default" :disabled="!validated" @click="insertProduct">Salvar Produto</button>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <div class="fill" v-if="loadingF">
        <div class="loading-modal">
          <img src="@/assets/loading.gif" class="loading-img" alt="">
        </div>
      </div>
  </v-container>
</template>

<script>
import axios from 'axios'
import config from '@/store/config'

import 'tui-color-picker/dist/tui-color-picker.css';
import 'tui-image-editor/dist/tui-image-editor.css';
import { ImageEditor } from '@toast-ui/vue-image-editor';


const api = axios.create({
    baseURL: config.baseURL
})

const delay = ms => new Promise(res => setTimeout(res, ms));


export default {
    name: 'NewProductPage',
    components: {
      'tui-image-editor': ImageEditor,
    },
    data () {
      return {
        validated: false,
        loading: false,
        loadingF: false,
        loadingPreview: false,
        userData: {},
        models: [
        ],

        sumPixelFem: 37, //37
        formData: {
          name: '',
          description: '',
          sellPrice: 0,
          promotionalPrice: 0
        },
        textError: '',
        options: {
          cssMaxWidth: 500,
          cssMaxHeight: 520,
        },
        useDefaultUI: false,
        genderEditorSelected: 1,
        colorEditorSelected: 6,

        canvasPreviews: [],

        estampa: null,
        idEstampaEditor: null,
        estampaName: null,
        modelSelected: null,
        colorsShow: [],
        mockupsFem: [],
        mockupsMas: [],
        idEstampasMockups: [],
      }
    },
    async mounted(){
      await this.init()
    },
    watch:{
      'formData.sellPrice': function (val) {
        this.validatePrices()
      },
      'formData.promotionalPrice': function (val) {
        this.validatePrices()
      }
    },
    methods:{
      async validatePrices(){
        if(this.formData.promotionalPrice > 0){
          let profit = this.formData.promotionalPrice - this.modelSelected.costPrice
          if(profit > 0){
            this.validated = true
          }else{
            this.validated = false
          }
          this.textError = profit > 0 ? '' : 'O valor promocional deve ser maior que o custo do produto'
        }else{
          let profit = this.formData.sellPrice - this.modelSelected.costPrice
          if(profit > 0){
            this.validated = true
          }else{
            this.validated = false
          }
          this.textError = profit > 0 ? '' : 'O valor de venda deve ser maior que o custo do produto'
        }
      },
      async scrollToTop() {
        window.scrollTo(0,0);
      },
      async loadingFull(state){
        await this.scrollToTop()
        this.loadingF = state
      },
      async init(){
        await this.scrollToTop()
        this.loading = true
        await this.getModels()
        this.loading = false
      },
      async getModels(){
        try {
          let response = await api.get('/models', await this.$store.getters.getConfig)
          this.models = response.data
        } catch (error) {
          console.log(error)
        }
      },
      async selectModel(model){
        this.loadingFull(true)
        this.modelSelected = model

        this.mockupsFem = model.mockups.filter(m => m.idGender == 2)
        this.mockupsMas = model.mockups.filter(m => m.idGender == 1)
        this.colorsShow = model.mockups.filter((arr, index, self) => index === self.findIndex((t) => (t.idColor === arr.idColor)))
        this.formData.sellPrice = model.costPrice * 2
        await delay(100)
        await this.$refs.editor.invoke('loadImageFromURL', model.mockups[0].image, 'principal');
        await this.selectGenderEditor(1)
        await this.selectColorEditor(this.colorsShow[0])
        this.loadingFull(false)
        console.log(this.$refs['editor'])
      },
      async getFile2(e){
          let file = e.target.files[0];
          this.estampaName = file.name
          let app = this
          let reader = new FileReader();
          reader.onloadend = async function() {
            app.estampa = reader.result
            let e = await app.$refs['editor'].invoke('addImageObject', reader.result);
            app.idEstampaEditor = e.id 

            let c = {
              scaleX: 0.1,
              scaleY: 0.1,
            }
            await app.$refs.editor.invoke('setObjectProperties', app.idEstampaEditor, c);
            await app.loadImageUploadInOthersCanvas(reader.result)
          }
          if (file) {
              reader.readAsDataURL(file);
          }
      },
      async loadImageUploadInOthersCanvas(image){
        if(this.canvasPreviews){
          for (const i of this.canvasPreviews) {
            let e = await this.$refs[`canva${i.id}${i.idGender}${i.idColor}`][0].invoke('addImageObject', image);
            this.idEstampasMockups.push({
              ref: `canva${i.id}${i.idGender}${i.idColor}`,
              idGender: i.idGender,
              id: e.id,
              mockup: i
            })

            let c = {
              scaleX: 0.1,
              scaleY: 0.1,
            }
            await this.$refs[`canva${i.id}${i.idGender}${i.idColor}`][0].invoke('setObjectProperties', e.id, c);
            await this.$refs[`canva${i.id}${i.idGender}${i.idColor}`][0].invoke('deactivateAll');
          
          }
        }
      },
      async onObjectScaled(){
        for (const i of this.idEstampasMockups) {
          let p = await this.$refs.editor.invoke('getObjectProperties', this.idEstampaEditor, {
              left: null,
              top: null,
              width: null,
              angle: null,
              height: null,
              opacity: null,
              scaleX: null,
              scaleY: null,
          });

          let c = {
              left: p.left,
              top: p.top,
              angle: p.angle,
              scaleX: p.scaleX,
              scaleY: p.scaleY,
          }

          console.log(`Genero Editor: ${this.genderEditorSelected} - Genero Preview: ${i.idGender}`)

          if(this.genderEditorSelected == 1 && i.idGender == 2){
            c.top = c.top + this.sumPixelFem;
          }

          if(this.genderEditorSelected == 2 && i.idGender == 1){
            c.top = c.top - this.sumPixelFem;
          }

          await this.$refs[i.ref][0].invoke('setObjectProperties', i.id, c);
          this.$refs[i.ref][0].invoke('deactivateAll');

        }
      },
      async selectColor(mockup){
        if(this.canvasPreviews.find(x => x.id == mockup.id)){
          await this.removeCanvaColor(mockup)
          return
        }
        this.loadingPreview = true
        this.canvasPreviews.push(mockup)
        let ref = `canva${mockup.id}${mockup.idGender}${mockup.idColor}`
        await delay(100)
        await this.$refs[ref][0].invoke('loadImageFromURL', mockup.image2, `canva${mockup.id}`);
        if(this.idEstampaEditor != null){
          await delay(100)
          let e = await this.$refs[ref][0].invoke('addImageObject', this.estampa);
          this.idEstampasMockups.push({
            ref,
            idGender: mockup.idGender,
            id: e.id,
            mockup
          })
          
          let p = await this.$refs.editor.invoke('getObjectProperties', this.idEstampaEditor, {
            left: null,
            top: null,
            angle: null,
            width: null,
            height: null,
            opacity: null,
            scaleX: null,
            scaleY: null,
          });
              

          let c = {
            left: p.left,
            top: p.top,
            angle: p.angle,
            scaleX: p.scaleX,
            scaleY: p.scaleY,
          }

          if(this.genderEditorSelected == 1 && mockup.idGender == 2){
            c.top = c.top + this.sumPixelFem;
          }

          if(this.genderEditorSelected == 2 && mockup.idGender == 1){
            c.top = c.top - this.sumPixelFem;
          }

          await this.$refs[ref][0].invoke('setObjectProperties', e.id, c);
          await this.$refs[ref][0].invoke('deactivateAll');
        }
        this.loadingPreview = false
      },
      async removeCanvaColor(mockup){
        const ref = `canva${mockup.id}${mockup.idGender}${mockup.idColor}`
        for (let i = 0; i < this.canvasPreviews.length; i++) {
          const element = this.canvasPreviews[i];
          if(element.id == mockup.id){
            this.canvasPreviews.splice(i, 1)
          }
        }

        for (let j = 0; j < this.idEstampasMockups.length; j++) {
          const element = this.idEstampasMockups[j];
          if(element.ref == ref){
            this.idEstampasMockups.splice(j, 1)
          }
        }
      },
      async selectColorEditor(c){
        this.colorEditorSelected = c.idColor
        this.colorsShow = this.modelSelected.mockups.filter((arr, index, self) => index === self.findIndex((t) => (t.idColor === arr.idColor && t.idGender == this.genderEditorSelected)))

        let mockup = this.modelSelected.mockups.find(m => m.idColor == c.idColor && m.idGender == this.genderEditorSelected)

        if(this.idEstampaEditor != null){
          await delay(100)
          var p = await this.$refs.editor.invoke('getObjectProperties', this.idEstampaEditor, {
            left: null,
            top: null,
            width: null,
            height: null,
            opacity: null,
            scaleX: null,
            scaleY: null,
          });
        }

        await this.$refs.editor.invoke('loadImageFromURL', mockup.image, 'principal');

        if(this.idEstampaEditor != null){
          await delay(100)
          let o = await this.$refs.editor.invoke('addImageObject', this.estampa);
          this.idEstampaEditor = o.id
        
          let c = {
            left: p.left,
            top: p.top,
            scaleX: p.scaleX,
            scaleY: p.scaleY,
          }
          await this.$refs.editor.invoke('setObjectProperties', this.idEstampaEditor, c);
        }
      },
      async selectGenderEditor(g){
        let previousGender = this.genderEditorSelected;
        this.genderEditorSelected = g
        
        console.log(this.modelSelected.mockups)

        this.colorsShow = this.modelSelected.mockups.filter((arr, index, self) => index === self.findIndex((t) => (t.idColor === arr.idColor && t.idGender == this.genderEditorSelected)))


        let mockup = this.modelSelected.mockups.find(m => m.idColor == this.colorEditorSelected && m.idGender == g)

        if(mockup == undefined){
          this.colorEditorSelected = this.colorsShow[0].idColor
          mockup = this.modelSelected.mockups.find(m => m.idColor == this.colorsShow[0].idColor && m.idGender == g)
        }
        if(this.idEstampaEditor != null){
          await delay(100)
          var p = await this.$refs.editor.invoke('getObjectProperties', this.idEstampaEditor, {
            left: null,
            top: null,
            width: null,
            angle: null, 
            height: null,
            opacity: null,
            scaleX: null,
            scaleY: null,
          });
        }
        

        await this.$refs.editor.invoke('loadImageFromURL', mockup.image, 'principal');

        if(this.idEstampaEditor != null){
          await delay(100)
          let o = await this.$refs.editor.invoke('addImageObject', this.estampa);
          this.idEstampaEditor = o.id
        
          let c = {
            left: p.left,
            top: p.top,
            angle: p.angle,
            scaleX: p.scaleX,
            scaleY: p.scaleY,
          }

          if(previousGender == 2 && g == 1){
            c.top = c.top - this.sumPixelFem;
          }

          if(previousGender == 1 && g == 2){
            c.top = c.top + this.sumPixelFem;
          }
          await this.$refs.editor.invoke('setObjectProperties', this.idEstampaEditor, c);
        }
      },
      async removeEstampa(){
        this.estampa = null
        this.estampaName = null
        this.idEstampaEditor = null
        this.idEstampasMockups = []
        this.$refs.editor.invoke('clearObjects');
        for (const i of this.canvasPreviews) {
          this.$refs[`canva${i.id}${i.idGender}${i.idColor}`][0].invoke('clearObjects');
        }
      },

      async insertProduct(){
        try {
          await this.loadingFull(true)
          let showcaseProducts = [];
          for (const i of this.idEstampasMockups) {
            let p = await this.$refs[i.ref][0].invoke('getObjectProperties', i.id, {
                left: null,
                top: null,
                width: null,
                angle: null,
                height: null,
                opacity: null,
                scaleX: null,
                scaleY: null,
            });

            let showcase = {
              id_model: i.mockup.idModel,
              id_gender: i.mockup.idGender,
              id_color: i.mockup.idColor,
              image: await this.$refs[i.ref][0].invoke('toDataURL'),
              print_position: JSON.stringify(p)
            }

            showcaseProducts.push(showcase);
          }

          let insertData = {
            productsName: {
              name: this.formData.name,
              description: this.formData.description,
              sell_price: this.formData.sellPrice,
              promotional_price: this.formData.promotionalPrice,
              print: this.estampa 
            },
            showcaseProducts
          }

          await api.post('/products', insertData, await this.$store.getters.getConfig)
          this.loadingFull(false)
          this.$router.push('/products')
          
        } catch (error) {
          this.loadingFull(false)
          console.log(error)
        }
      
      }
    }
}
</script>

<style scoped>
  *{
    font-family: "Outfit", sans-serif;
  }

  .text-gray-700 {
      --tw-text-opacity: 1;
      color: rgb(55 65 81 / var(--tw-text-opacity));
  }

  .bg-gray-100 {
      --tw-bg-opacity: 1;
      background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  }

  .bg-gray-50 {
      --tw-bg-opacity: 1;
      background-color: rgb(249 250 251 / var(--tw-bg-opacity));
  }

  .border-gray-200 {
      --tw-border-opacity: 1;
      border-color: rgb(229 231 235 / var(--tw-border-opacity));
  }

  .color-selected{
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
  .input-default{
    width: 100%;
    padding: .7rem;
    color: #404049;
    font-size: 1.2rem;
    font-weight: 400;
    border-radius: 5px;
    border: 1px solid rgba(64, 64, 73, .3);

  }

  .button-default{
    width: 100%;
    padding: .5rem;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 5px;
    background-color: #f70293;
    border: 1px solid #f70293;
    cursor: pointer;
  }

  .button-default:disabled{
    background-color: #f70293;
    border: 1px solid #f70293;
    cursor: not-allowed;
    opacity: .5;
  }

  .button-select{
    padding: .7rem;
    color: #000000;
    font-size: 1rem;
    font-weight: 400;
    border-radius: 50px;
    background-color: #ffffff;
    border: 1px solid #d4d4d4;
    margin-left: 10px;
    cursor: pointer;
  }

  .button-select.active{
    background-color: #f70293;
    border: 1px solid #f70293;
    color: #ffffff;
  }

  .gender-btns{
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

 
  .container-page{
    width: 84%;
  }


  .loading{
    display: block;
    margin: 0 auto;
    width: 50%;
  }

  .loading-preview{
    display: block;
    margin: 0 auto;
    width: 100%;
  }

  @media screen and (max-width: 600px){
    .container-page{
      width: 100% !important;
      padding-right: 12px;
    }

    .loading{
      width: 100%;
    }
  }

  .card-product{
    box-shadow: none !important;
    border: 1px solid;
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity));
  }

  .price{
    font-weight: 400;
    font-size: 1.1rem;
  }

  .card-product .card-product-text {
    padding: .4rem;
  }

  .card-product .card-product-text .name{
    margin-top: 1rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
  }

  .card-product .card-product-text .sell{
    font-size: .8rem;
    margin: 0;
    font-weight: 400;
  }

  .card-product .img{
    position: relative;
  }

  .card-product .icon-edit{
    position: absolute;
    font-size: 1.2rem;
    top: 5px;
    right: 5px;
    color: #f70293;
    cursor: pointer;
  }

  .cores ul{
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .cores .cor{
    width: 2.25rem;
    height: 2.25rem;
    border: 1px solid #606060;
    border-radius: 20px;
    margin: 5px;
  }

  .cores .cor.active{
    border: 3px solid #f70293;
  }


  .fill{
    background-color: #0000008f;
    z-index: 99999999;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .loading-modal img{
    display: block;
    margin: 0 auto;
    margin-top: 22vh;
    width: 50%;
  }

  .card-result{
    margin-top: 2vh;
  }

  .txt-error{
    color: red;
    font-size: .8rem;
  }
</style>