<template>
  <v-container class="container-page">
    <Steps v-if="step < 3" :userData="userData" :storeData="storeData" :step="step" @updatestep="updateStep" />
    <Loading v-if="loading" />
    <Dashboard v-if="step == 3" :userData="userData" :storeData="storeData" :productsNews="productsNews"
      @updateProducts="getProductsNews" />
  </v-container>
</template>

<script>
import Loading from '@/components/Loading.vue'
import Dashboard from '@/components/Dashboard.vue'
import Steps from '@/components/Steps.vue'
import axios from 'axios'
import config from '@/store/config'
const api = axios.create({
  baseURL: config.baseURL
})

export default {
  name: 'HomePage',
  metaInfo() {
    return {
      title: `Dashboard - Beeasy INK - ${this.userData.fullName}`
    }
  },
  components: {
    Loading,
    Steps,
    Dashboard
  },
  data() {
    return {
      step: 1,
      loading: false,
      productsNews: null,
      userData: {},
      storeData: {},
    }
  },
  async mounted() {
    await this.init()
  },
  methods: {
    async init() {
      this.loading = true
      this.userData = this.$store.getters.getUserData
      this.storeData = this.$store.getters.getStoreData

      await this.getProducts();
      this.loading = false
      if (this.step == 3) {
        await this.getProductsNews()
      }
    },
    async getProducts() {
      try {
        let response = await api.get(`/store/${this.userData.idStore}/products`, await this.$store.getters.getConfig)
        let qntProducts = response.data.length
        if (qntProducts == 0) {
          this.step = 1
        }
        if (qntProducts > 0 && !this.storeData.cpf) {
          this.step = 2
        }
        if (qntProducts > 0 && this.storeData.cpf) {
          this.step = 3
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getProductsNews() {
      try {
        let response = await api.get(`/store/${this.userData.idStore}/news/products`, await this.$store.getters.getConfig)
        this.productsNews = response.data
      } catch (error) {
        console.log(error);
      }
    },
    async updateStep(e) {
      this.step = e
    }
  }
}
</script>

<style scoped>
* {
  font-family: "Outfit", sans-serif;
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.input-default {
  width: 100%;
  padding: .5rem;
  color: #404049;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 5px;
  border: 1px solid rgba(64, 64, 73, .3);

}

.button-default {
  width: 100%;
  padding: .5rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 5px;
  background-color: #00AF7D;
  border: 1px solid #00AF7D;
  cursor: pointer;
}
</style>