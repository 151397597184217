<template>
    <v-container fluid class="container-page">
      <v-row>
        <v-col cols="12" md="12">
          <h2>Modelos</h2>
        </v-col>
        <v-col>
          <v-card>
            <v-card-title>
              <v-container fluid>
                <v-row>
                  <v-col cols="12" md="6">
                    <div class="form-item">
                      <div class="form-group">
                        <input type="text" label="Search" class="input-default" v-model="search" placeholder="Busque seu modelo">
                      </div>
                    </div>
                  </v-col>
                  <v-col>
                    <button class="button-default" @click="mNewModel = true">Novo Modelo</button>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
            <img src="@/assets/loading.gif" class="loading" alt="" v-if="loading">
            <v-simple-table v-if="!loading">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Descrição
                    </th>
                    <th class="text-left">
                      Preço de Custo
                    </th>
                    <th class="text-left">
                      Mockups
                    </th>
                    <th class="text-left">
                      
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="m in models"
                    :key="m.id"
                  >
                    <td>{{ m.description }}</td>
                    <td><strong>{{ m.costPrice.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}</strong> <small>({{ m.originalPrice.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }} + {{ m.beeasyCommission.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }})</small></td>
                    <td>{{ m.mockups.length }}</td>
                    <td>
                      <v-btn
                        color="#f70293"
                        rounded
                        text
                        @click="$router.push(`/model/${m.id}`)"
                      >
                        <v-icon dark>
                          mdi-tshirt-crew
                        </v-icon>
                      </v-btn>

                      <v-btn
                        color="#f70293"
                        rounded
                        text
                        @click="prepareEdit(m)"
                      >
                        <v-icon dark>
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>


      <!--  MODAIS -->
      <v-dialog
        v-model="mNewModel"
        persistent
        style="z-index: 9999"
        max-width="700"
      >
        <v-card>
          <v-card-title class="text-h5">
            Novo Modelo
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <label for="description">Descrição</label>
                  <input type="text" id="description" placeholder="Descrição" class="input-default" v-model="insertData.description">
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <label for="costPrice">Preço de Custo</label>
                  <input type="number" id="costPrice" placeholder="Preço de Custo" class="input-default" v-model="insertData.original_price">
                  <p>
                    + Comissão Beeasy: {{ (insertData.original_price * 0.06).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="#f70293"
              text
              @click="mNewModel = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="#f70293"
              text
              @click="insertModel"
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <v-dialog
        v-model="mEditModal"
        persistent
        style="z-index: 9999"
        max-width="700"
      >
        <v-card>
          <v-card-title class="text-h5">
            Novo Modelo
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <label for="description">Descrição</label>
                  <input type="text" id="description" placeholder="Descrição" class="input-default" v-model="insertData.description">
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <label for="costPrice">Preço de Custo</label>
                  <input type="number" id="costPrice" placeholder="Preço de Custo" class="input-default" v-model="insertData.original_price">
                  <p>
                    + Comissão Beeasy: {{ (insertData.original_price * 0.06).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="#f70293"
              text
              @click="mEditModal = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="#f70293"
              text
              @click="editModel"
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <div class="fill" v-if="loadingInsert">
        <div class="loading-modal">
          <img src="@/assets/loading.gif" class="loading-img" alt="">
        </div>
      </div>
    </v-container>
  </template>
  
<script>
import {mask} from 'vue-the-mask'
import {VMoney} from 'v-money'
import axios from 'axios'
import config from '@/store/config'

const api = axios.create({
    baseURL: config.baseURL
})
export default {
  name: 'ModelsPage',
  metaInfo() {
      return {
        title: `Modelos - Beeasy INK - ${this.userData.fullName}`
      }
  },
  directives: {
    mask,
    money: VMoney
  },
  data () {
    return {
      mNewModel: false,
      mEditModal: false,
      loading: false,
      loadingInsert: false,
      search: '',
      insertData:{
        description: '',
        original_price: ''
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2
      },
      userData: this.$store.getters.getUserData,
      models: [
      ],
    }
  },
  async mounted(){
    await this.init()
  },
  methods: {
    async init(){
      this.loading = true
      let role = this.$store.getters.getUserData.role
      if(role !== 'admin'){
        this.$router.push('/home')
      }
      await this.getModels()
      this.loading = false
    },
    async getModels(){
      try {
        let response = await api.get('/models', await this.$store.getters.getConfig)
        this.models = response.data
      } catch (error) {
        console.log(error)
      }
    },
    async insertModel(){
      try {
        let response = await api.post('/models', this.insertData, await this.$store.getters.getConfig)
        this.models.push(response.data)
        this.mNewModel = false
        this.insertData = {
          description: '',
          original_price: ''
        }
      } catch (error) {
        console.log(error)
      }
    },
    prepareEdit(model){
      this.insertData.id = model.id
      this.insertData.description = model.description
      this.insertData.original_price = model.originalPrice
      this.mEditModal = true
    },
    async editModel(){
      try {
        let response = await api.put(`/models/${this.insertData.id}`, this.insertData, await this.$store.getters.getConfig)
        await this.getModels()
        this.mEditModal = false
        this.insertData = {
          description: '',
          original_price: ''
        }
      } catch (error) {
        console.log(error)
      }
    }
  
  }
}
</script>
  

  <style scoped>
  *{
    font-family: "Outfit", sans-serif;
  }

  .text-gray-700 {
      --tw-text-opacity: 1;
      color: rgb(55 65 81 / var(--tw-text-opacity));
  }

  .bg-gray-100 {
      --tw-bg-opacity: 1;
      background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  }
  .input-default{
    width: 100%;
    padding: .5rem;
    color: #404049;
    font-size: 1rem;
    font-weight: 400;
    border-radius: 5px;
    border: 1px solid rgba(64, 64, 73, .3);

  }

  .button-default{
    width: 100%;
    padding: .5rem;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 5px;
    background-color: #f70293;
    border: 1px solid #f70293;
    cursor: pointer;
  }
  
  .container-page{
    width: 84%;
  }

  .loading{
    display: block;
    margin: 0 auto;
    width: 50%;
  }

  @media screen and (max-width: 600px){
    .container-page{
      width: 100% !important;
      padding-right: 12px;
    }
    .loading{
      width: 100%;
    }
  }


  .fill{
    background-color: #0000008f;
    z-index: 99999999;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 150vh;
  }

  .loading-modal img{
    display: block;
    margin: 0 auto;
    margin-top: 22vh;
    width: 50%;
  }


  
  </style>