<template>
  <v-container fluid class="container-page">
    <v-row>
      <v-col cols="12" md="12">
        <v-row>
          <v-col cols="12" md="12">
            <h2>Pedidos</h2>
          </v-col>
          <v-col>
            <v-data-table :headers="headers" :items="orders" :page.sync="page" :items-per-page="itemsPerPage"
              hide-default-footer class="elevation-1" @page-count="pageCount = $event">
              <template v-slot:[`item.items`]="{ item }">
                <div class="column-pos">
                  <span class="column-title">BEE{{ (item.id + "").padStart(6, "0") }}</span><br>
                  <span class="column-subtitle">{{ item.products.length }} produto(s)</span>
                </div>
              </template>

              <template v-slot:[`item.date`]="{ item }">
                <div class="column-pos">
                  <span class="column-title">{{ new Date(item.createdAt).toLocaleDateString() }}</span><br>
                  <span class="column-subtitle">{{ new Date(item.createdAt).toLocaleTimeString() }}</span>
                </div>
              </template>

              <template v-slot:[`item.comission`]="{ item }">
                <div class="column-pos">
                  <span class="column-title" v-if="item.releaseDate">{{ item.releaseDate == null ? 'Calculando' : new Date(item.releaseDate).toLocaleDateString() }}</span>
                  <span class="column-title" v-else>-</span>
                  <br>
                  <span class="column-subtitle"></span>
                </div>
              </template>

              <template v-slot:[`item.status`]="{ item }">
                <div class="column-pos">
                  <span class="column-title"
                    v-if="item.chargeStatus == 'settled' || item.chargeStatus == 'paid' || item.chargeStatus == 'approved'">
                    <v-chip class="ma-2" color="green" outlined>
                      Pago
                    </v-chip>
                  </span>
                  <span class="column-title" v-if="item.chargeStatus == 'unpaid'">
                    <v-chip class="ma-2" color="red" outlined>
                      Vencido
                    </v-chip>
                  </span>

                  <span class="column-title" v-if="item.chargeStatus == 'waiting'">
                    <v-chip class="ma-2" color="primary" outlined>
                      Aguardando Pagamento
                    </v-chip>
                  </span>
                  <br>
                  <div v-if="item.chargeProvider == null">
                    Buscando...
                  </div>
                  <div v-if="item.chargeProvider == 'efi'">
                    PIX
                  </div>
                  <div v-if="item.chargeProvider == 'mercadopago'">
                    <span class="column-subtitle">Cartão de Crédito</span>
                  </div>

                </div>
              </template>

              <template v-slot:[`item.price`]="{ item }">
                <div class="column-pos">
                  <span class="column-title">{{ item.total.toLocaleString('pt-BR', {
                    style: 'currency', currency: 'BRL'
                  })
                    }}</span><br>
                  <span class="column-subtitle">{{ (item.subtotal - item.costPrice).toLocaleString('pt-BR', {
                    style:
                      'currency', currency: 'BRL'
                  }) }}</span>
                </div>
              </template>

              <template v-slot:[`item.costumer`]="{ item }">
                <div class="column-pos">
                  <span class="column-title">{{ item.name }}</span>
                </div>
              </template>

              <template v-slot:[`item.shipping`]="{ item }">
                <div class="column-pos">
                  <span class="column-title" v-if="item.shippingStatus == 'unshipped'">
                    <v-chip class="ma-2" outlined>
                      Cancelado
                    </v-chip>
                  </span>
                  <span v-else>
                    <span class="column-title" v-if="item.shippingStatus == 'shipped'">
                      <v-chip class="ma-2" outlined color="primary">
                        Enviado
                      </v-chip><br>
                      <small>{{ item.shippingTrackingNumber }}</small>
                    </span>
                    <span class="column-title" v-else>
                      <v-chip class="ma-2" outlined color="orange">
                        Preparando
                      </v-chip>
                    </span>
                  </span>
                </div>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-btn color="#00AF7D" outlined @click="openDialog(item)">Detalhes</v-btn>
              </template>
            </v-data-table>
            <div class="text-center pt-2">
              <v-pagination v-model="page" :length="pageCount"></v-pagination>
              <!-- <v-text-field
                    :value="itemsPerPage"
                    label="Produtos por página"
                    type="number"
                    min="-1"
                    max="15"
                    @input="itemsPerPage = parseInt($event, 10)"
                  ></v-text-field> -->
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <Loading v-if="loadingPage" />

   <!-- Dialog for Order Details -->
   <v-dialog v-model="dialog" max-width="800" class="dialog-spacing" style="z-index: 9999;">
      <v-card>
        <v-card-title class="headline">Detalhes do Pedido</v-card-title>
        <v-card-text>
          <div v-if="selectedOrder">
            <div class="section-header">Venda</div>
            <div class="totals">
              <div class="total-item total-item-alt">
                <span><strong>ID</strong></span>
                <span class="spanValue"> BEE{{ (selectedOrder.id + "").padStart(6, "0") }}</span>
              </div>
              <div class="total-item">
                <span><strong>Data</strong></span>
                <span class="spanValue"> {{ new Date(selectedOrder.createdAt).toLocaleDateString() }}</span>
              </div>
              <div class="total-item total-item-alt">
                <span><strong>Cliente</strong></span>
                <span class="spanValue"> {{ selectedOrder.name }}</span>
              </div>
              <div class="total-item">
                <span><strong>Contato</strong></span>
                <span class="spanValue"> {{ selectedOrder.email }} | {{ selectedOrder.phone }}</span>
              </div>
              <div class="total-item total-item-alt">
                <span><strong>Subtotal</strong></span>
                <span class="spanValue"> {{ selectedOrder.subtotal.toLocaleString('pt-BR', {
                  style: 'currency', currency: 'BRL'
                }) }}</span>
              </div>
              <div class="total-item">
                <span><strong>Frete</strong></span>
                <span class="spanValue"> {{ selectedOrder.shipping.toLocaleString('pt-BR', {
                  style: 'currency', currency: 'BRL'
                }) }}</span>
              </div>
              <div class="total-item total-item-alt">
                <span><strong>Total</strong></span>
                <span class="spanValue"> {{ selectedOrder.total.toLocaleString('pt-BR', {
                  style: 'currency', currency: 'BRL'
                }) }}</span>
              </div>
            </div>
            <div class="section-header">Produtos</div>
            <div class="totals">
              <div class="total-item total-item-alt">
                <span v-for="(product, index) in selectedOrder.products" :key="product.id">
                  <strong>{{ product.quantity }}x</strong> - {{ product.product.name }}
                  <span v-if="index < selectedOrder.products.length - 1">, </span>
                </span>
              </div>
            </div>
            <div class="section-header">Despesas</div>
            <div class="totals">
              <div class="total-item total-item-alt">
                <span><strong>Taxa de Transação</strong></span>
                <span class="spanValue">{{ selectedOrder.transactionFee.toLocaleString('pt-BR', {
                  style:
                    'currency', currency: 'BRL'
                }) }}</span>
              </div>
              <div class="total-item total-item-alt">
                <span><strong>Total Despesas</strong></span>
                <span class="spanValue"> {{ calculateExpenses(selectedOrder).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }) }}</span>
              </div>
            </div>
            <div class="section-header">Total</div>
            <div class="totals">
              <div class="total-item total-item-alt">
                <span><strong>Lucro Bruto</strong></span>
                <span class="spanValue">{{ calculatedSubProfit(selectedOrder).toLocaleString('pt-BR', {
                  style:
                    'currency', currency: 'BRL'
                }) }}</span>
              </div>
              <div class="total-item">
                <span><strong>Lucro Líquido</strong></span>
                <span class="spanValue"> {{ (calculatedSubProfit(selectedOrder) -
                  calculateExpenses(selectedOrder)).toLocaleString('pt-BR',
                    {
                      style: 'currency',
                      currency: 'BRL'
                    }) }}</span>
              </div>
            </div>
          </div>
          <div v-else>
            <p>Carregando...</p>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#00AF7D" text @click="closeDialog">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Loading from '@/components/Loading.vue'
import axios from 'axios'
import config from '@/store/config'
const api = axios.create({
  baseURL: config.baseURL
})

export default {
  name: 'OrdersPage',
  metaInfo() {
    return {
      title: `Pedidos - Beeasy INK - ${this.userData.fullName}`
    }
  },
  components: {
    Loading
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      headers: [
        {
          text: 'Venda/Itens',
          align: 'center',
          sortable: false,
          value: 'items',
        },
        { text: 'Data/Hora', value: 'date', align: 'center', },
        { text: 'Data Repasse', value: 'comission', align: 'center', },
        { text: 'Status/Método', value: 'status', align: 'center', },
        { text: 'Faturado/Lucro', value: 'price', align: 'center', },
        { text: 'Cliente', value: 'costumer', align: 'center', },
        { text: 'Status Entrega', value: 'shipping', align: 'center', },
        { text: 'Ações', value: 'actions', align: 'center', sortable: false },
      ],
      orders: [],
      userData: {},
      storeData: {},
      loadingPage: false,
      dialog: false,
      selectedOrder: null
    }
  },
  async mounted() {
    await this.init()
    this.$store.commit('insertLog',{ action: 'Navegacao',  description: `[${this.userData.fullName}] acessou a página de pedidos`})
  },
  methods: {
    async init() {
      this.loadingPage = true
      this.userData = this.$store.getters.getUserData
      this.storeData = this.$store.getters.getStoreData
      await this.getOrders()
      this.loadingPage = false
    },
    async getOrders() {
      try {
        const { data } = await api.get(`/orders`, await this.$store.getters.getConfig)
        this.orders = data
      } catch (error) {
        console.error(error)
      }
    },
    openDialog(item) {
      this.selectedOrder = item
      this.dialog = true
      this.$store.commit('insertLog',{ action: 'Consulta',  description: `[${this.userData.fullName}] acessou detalhes do pedido {${item.id}}`})
    },
    closeDialog() {
      this.dialog = false
      this.selectedOrder = null
    },
    calculateExpenses(order) {
      return order.transactionFee
    },
    calculatedSubProfit(order) {
      return order.subtotal - order.costPrice;
    },
    calculatedProfit(order) {
      return order.invoiceFee - order.transactionFee;
    }
  }
}
</script>

<style scoped>
* {
  font-family: "Outfit", sans-serif;
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.input-default {
  width: 100%;
  padding: .5rem;
  color: #404049;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 5px;
  border: 1px solid rgba(64, 64, 73, .3);
}

.button-default {
  width: 100%;
  padding: .5rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 5px;
  background-color: #00AF7D;
  border: 1px solid #00AF7D;
  cursor: pointer;
}

.column-pos {
  text-align: center;
  color: rgb(55 65 81);
  padding: .5rem;
}

.column-title {
  font-size: 1rem;
  font-weight: 400;
}

.column-subtitle {
  font-size: .8rem;
  font-weight: 400;
}

/* Estilo adicional para garantir espaçamento em telas móveis */
.dialog-spacing {
  margin: 16px !important;
}

@media (min-width: 600px) {
  .dialog-spacing {
    margin: 24px !important;
  }
}


* {
  font-family: "Outfit", sans-serif;
}

.spanValue {
  text-align: right;
}

.order-info {
  margin-bottom: 20px;
}

.product-info {
  margin-bottom: 20px;
}

.order-info-item {
  padding: 8px 0;
  background-color: #fff;
}

.order-info-item:nth-child(even) {
  background-color: #f9f9f9;
}

.section-header {
  background-color: #00af7dba;
  padding: 10px;
  font-weight: bold;
  margin-bottom: 10px;
}

.totals {
  padding: 10px;
  background-color: #fff;
}

.total-item {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
}

.total-item-alt {
  background-color: #f9f9f9;
}

.total-item span {
  display: inline-block;
  min-width: 100px;
}

.total-item-bold {
  font-weight: bold;
}
</style>
