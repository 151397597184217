<template>
  <v-container fluid class="container-page">
    <v-row>
      <v-col cols="12" md="12">
        <v-row>
          <v-col cols="12">
            <h2>Configurar Loja</h2>
          </v-col>
          <v-col>
            <v-card>
              <v-card-text>
                <v-container fluid>
                  <v-row>
                    <v-col cols="12" md="12">
                      <h3>
                        NOME E LINK DA LOJA
                      </h3>
                    </v-col>
                    <v-col cols="12" md="6">
                      <label for="name">Nome da Loja</label>
                      <input type="text" class="input-default" id="name" v-model="storeData.name"
                        placeholder="Nome da Loja">
                    </v-col>
                    <v-col cols="12" md="6">
                      <label for="cpf">CPF</label>
                      <input type="text" class="input-default" id="cpf" v-model="storeData.cpf" :readonly="cpfExists"
                        v-mask="['###.###.###-##']" placeholder="000.000.000-00">
                      <strong>
                        Certifique-se de que o CPF informado é o mesmo que está cadastrado em sua conta bancária como
                        PIX.
                      </strong>
                    </v-col>
                    <v-col cols="12" md="12">


                      <label for="link">Link da loja</label>
                      <v-btn icon small @click="linkDialog = true">
                        <v-icon color="red">mdi-help-circle</v-icon>
                      </v-btn>
                      <div class="input-group">
                        <span class="input-group-text">{{ config.url }}/</span>
                        <input type="text" class="input-default" id="link" v-model="storeData.slug"
                          @keyup="verifySlug()">
                      </div>
                      <small v-html="returnVerify"></small>
                    </v-col>

                    <v-col cols="12" md="12">
                      <h3>
                        <v-badge color="green" content="Novo" inline>
                          REDES SOCIAIS
                        </v-badge>
                      </h3>
                    </v-col>

                    <v-col cols="12" md="6">
                      <label for="facebook">Facebook</label>
                      <div class="input-group">
                        <span class="input-group-text">facebook.com/</span>
                        <input type="text" class="input-default" id="facebook" v-model="facebookUsername"
                          placeholder="Nome de usuário">
                      </div>
                    </v-col>

                    <v-col cols="12" md="6">
                      <label for="instagram">Instagram</label>
                      <div class="input-group">
                        <span class="input-group-text">instagram.com/</span>
                        <input type="text" class="input-default" id="instagram" v-model="instagramUsername"
                          placeholder="Nome de usuário">
                      </div>
                    </v-col>

                    <!-- <v-col cols="12" md="6">
                      <label for="tiktok">TikTok</label>
                      <div class="input-group">
                        <span class="input-group-text">tiktok.com/@</span>
                        <input type="text" class="input-default" id="tiktok" v-model="tiktokUsername"
                          placeholder="Nome de usuário">
                      </div>
                    </v-col> -->

                    <v-col cols="12" md="6">
                      <label for="whatsapp">WhatsApp</label>
                      <div class="input-group">
                        <span class="input-group-text">wa.me/</span>
                        <input type="text" class="input-default" id="whatsapp" v-model="whatsappNumber"
                          placeholder="Número com DDD">
                      </div>
                    </v-col>

                    <v-col cols="12" md="12">
                      <h3>LOGO E BANNER</h3>
                    </v-col>
                    <v-col cols="12">
                      <v-container class="card-logo" @click="$refs.logo.click()">
                        <v-row>
                          <v-col cols="6" md="4">
                            <img :src="storeData.logo" style="width: 70%;display: block;margin: 0 auto;margin-top: 22px"
                              alt="">
                          </v-col>
                          <v-col cols="12" md="6">
                            <input type="file" class="input-default" ref="logo" id="logo" @change="getFile"
                              placeholder="Logo" style="opacity: 0;position: absolute;left: -100rem;">
                            <p>Clique aqui para carregar sua logo.<br>
                              Dimensões: 500x125<br>
                              Formato: PNG<br>
                              Tamanho Máx: 2MB</p>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>

                    <v-col cols="12">
                      <v-container class="card-logo" @click="$refs.topCase.click()">
                        <v-row>
                          <v-col cols="6" md="4">
                            <img :src="storeData.topCase"
                              style="width: 70%;display: block;margin: 0 auto;margin-top: 10px" alt="">
                          </v-col>
                          <v-col cols="12" md="6">
                            <input type="file" class="input-default" ref="topCase" id="logo" @change="getFile2"
                              placeholder="Logo" style="opacity: 0;position: absolute;left: -100rem;">
                            <p>Clique aqui para carregar seu banner.<br>
                              Dimensões: 1400x620<br>
                              Formato: PNG<br>
                              Tamanho Máx: 2MB</p>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>

                    <v-col>
                      <button :class="{ 'button-default': true, 'disabled': !verified }" @click="save"
                        :disabled="!verified" v-if="!loading">Salvar</button>
                      <button :class="{ 'button-default': true, 'disabled': true }" v-if="loading">Aguarde...</button>

                      <!-- <p>{{ token }}</p> -->
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="saved" max-width="300">
      <v-card>
        <v-card-text>
          <img src="../../assets/success.gif" style="width: 100%;display: block;margin: 0 auto" alt="">
          <h2>
            Dados salvos com sucesso! para ver as alterações, clique no botão abaixo.
          </h2>
          <br>
          <v-btn color="pink" dark block :href="urlStore" target="_blank">Ver minha loja</v-btn>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="pink darken-1" text @click="saved = false">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="linkDialog" max-width="400">
      <v-card>
        <v-card-title class="headline">O que é o link?</v-card-title>
        <v-card-text>
          O link é utilizado para criar um endereço único para sua loja no site. Ele será parte da URL da sua loja.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="pink" text @click="linkDialog = false">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>



<script>
import { mask } from 'vue-the-mask'
import axios from 'axios'
import config from '@/store/config'
const api = axios.create({
  baseURL: config.baseURL
})

function validateCpf(cpf) {
  cpf = cpf.replace(/\D/g, '');
  if (cpf.toString().length != 11 || /^(\d)\1{10}$/.test(cpf)) return false;
  var result = true;
  [9, 10].forEach(function (j) {
    var soma = 0, r;
    cpf.split(/(?=)/).splice(0, j).forEach(function (e, i) {
      soma += parseInt(e) * ((j + 2) - (i + 1));
    });
    r = soma % 11;
    r = (r < 2) ? 0 : 11 - r;
    if (r != cpf.substring(j, j + 1)) result = false;
  });
  return result;
}

function toSnakeCase(obj) {
  const result = {};
  for (const key in obj) {
    const snakeKey = key.replace(/([A-Z])/g, "_$1").toLowerCase();
    result[snakeKey] = obj[key];
  }
  return result;
}

function testDomain(domain) {
  return domain.indexOf('.') > -1;
}

export default {
  name: 'StorePage',
  metaInfo() {
    return {
      title: `Configurações da Loja - Beeasy INK - ${this.userData.fullName}`
    }
  },
  directives: {
    mask
  },
  data() {
    return {
      config,
      term: '',
      returnVerify: '',
      saved: false,
      loading: false,
      cpfExists: false,
      verified: true,
      token: localStorage.token,
      urlStore: '',
      userData: {},
      storeData: {},
      oldStoreData: {},
      products: [],
      productsFiltered: [],
      node_env: process.env.NODE_ENV,
      facebookUsername: '',
      instagramUsername: '',
      tiktokUsername: '',
      whatsappNumber: '',
      linkDialog: false
    }
  },
  async mounted() {
    await this.init()
    this.$store.commit('insertLog',{ action: 'Navegacao',  description: `[${this.userData.fullName}] acessou a página de configurações da loja.`})
  },
  methods: {
    async init() {
      this.userData = this.$store.getters.getUserData
      this.storeData = this.$store.getters.getStoreData
      this.oldStoreData = this.$store.getters.getStoreData
      if (this.storeData.cpf != null) {
        this.cpfExists = true
      }

      await this.prepareUrlStore()

      // Preenche os campos com os valores atuais
      this.facebookUsername = this.storeData.facebookLink ? this.storeData.facebookLink.replace('https://facebook.com/', '') : '';
      this.instagramUsername = this.storeData.instagramLink ? this.storeData.instagramLink.replace('https://instagram.com/', '') : '';
      this.tiktokUsername = this.storeData.tiktokLink ? this.storeData.tiktokLink.replace('https://tiktok.com/@', '') : '';
      this.whatsappNumber = this.storeData.whatsappLink ? this.storeData.whatsappLink.replace('https://wa.me/', '') : '';
    },
    async prepareUrlStore() {
      if (this.node_env === 'development') {
        // Ambiente de desenvolvimento
        this.urlStore = `https://teste.${config.url}/${this.$store.state.storeData.slug}`;
      } else {
        // Ambiente de produção
        if (testDomain(this.$store.state.storeData.slug)) {
          // Se o slug contém um ponto, usar como subdomínio
          this.urlStore = `https://${this.$store.state.storeData.slug}`;
        } else {
          // Caso contrário, usar como parte do caminho
          this.urlStore = `https://${config.url}/${this.$store.state.storeData.slug}`;
        }
      }
    },
    async verifySlug() {
      let app = this
      let store = JSON.parse(localStorage.getItem('store'))
      if (store.slug == this.storeData.slug) {
        app.verified = true
        return
      }

      this.storeData.slug = this.storeData.slug.replace(/[^a-zA-Z0-9-]/g, '').trim().toLowerCase()
      if (this.storeData.slug) {
        await api.get(`/stores/slug/${this.storeData.slug}`).then((response) => {
          if (response.data == false) {
            app.returnVerify = '<b style="color: green;">Link disponível</b>'
            app.verified = true
          } else {
            app.returnVerify = '<b style="color: red;">Link indisponível</b>'
            app.verified = false
          }
        })
      }
    },
    async getFile(e) {
      let file = e.target.files[0];
      let app = this
      let reader = new FileReader();
      reader.onloadend = function () {
        app.storeData.logo = reader.result
      }
      if (file) {
        reader.readAsDataURL(file);
      }
    },
    async getFile2(e) {
      let file = e.target.files[0];
      let app = this
      let reader = new FileReader();
      reader.onloadend = function () {
        app.storeData.topCase = reader.result
      }
      if (file) {
        reader.readAsDataURL(file);
      }
    },
    async save() {
      this.loading = true;
      try {
        if (this.storeData.name === '' || this.storeData.cpf === '' || this.storeData.cpf === null || this.storeData.slug === '' || this.storeData.logo === '' || this.storeData.topCase === '') {
          this.$toast.warning('Preencha todos os campos')
          this.loading = false;
          return
        }

        if (validateCpf(this.storeData.cpf) == false) {
          this.$toast.warning('CPF inválido')
          return
        }
        let store = JSON.parse(localStorage.getItem('store'))

        let whatsapp = this.whatsappNumber.replace(/\D/g, '').length == 13 ? this.whatsappNumber.replace(/\D/g, '') : '55' + this.whatsappNumber.replace(/\D/g, '')
        if (this.whatsappNumber !== '' && (whatsapp.length < 11 || whatsapp.length > 13)) {
          this.$toast.warning('Número de WhatsApp inválido')
          this.loading = false;
          return
        }
        const socialLinks = {
          facebookLink: this.facebookUsername ? `https://facebook.com/${this.facebookUsername}` : '',
          instagramLink: this.instagramUsername ? `https://instagram.com/${this.instagramUsername}` : '',
          tiktokLink: this.tiktokUsername ? `https://tiktok.com/@${this.tiktokUsername}` : '',
          whatsappLink: this.whatsappNumber ? `https://wa.me/` + whatsapp : ''
        }

        let updateData = toSnakeCase({
          name: this.storeData.name,
          cpf: this.storeData.cpf,
          slug: this.storeData.slug,
          logo: this.storeData.logo,
          topCase: this.storeData.topCase,
          ...socialLinks
        });

        if (updateData.slug == store.slug) {
          delete updateData.slug
        }

        if (updateData.logo == store.logo) {
          delete updateData.logo
        }

        if (updateData.top_case == store.topCase) {
          delete updateData.top_case
        }

        this.$toast.info('Aguarde...')

        let response = await api.put(`/stores/${this.storeData.id}`, updateData, await this.$store.getters.getConfig)
        localStorage.setItem('store', JSON.stringify(response.data))
        this.$store.commit('insertLog',{ action: 'Atualizacao',  description: `[${this.userData.fullName}] atualizou as informações da loja, dados: ${JSON.stringify(updateData)}`})
        this.$store.commit('update_store', response.data)
        this.$toast.success('Informações salvas com sucesso!')
        this.saved = true;
        this.cpfExists = true;
        this.loading = false;
        await this.prepareUrlStore()

      } catch (error) {
        console.log(error)
        this.$toast.error('Erro ao salvar informações')
        this.loading = false;
      }
    }

  }
}
</script>



<style scoped>
* {
  font-family: "Outfit", sans-serif;
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.input-default {
  width: 100%;
  padding: .5rem;
  color: #404049;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 5px;
  border: 1px solid rgba(64, 64, 73, .3);
}

.input-group {
  display: flex;
  align-items: center;
}

.input-group-text {
  background-color: #f0f0f0;
  border: 1px solid rgba(64, 64, 73, .3);
  border-radius: 5px 0 0 5px;
  padding: .5rem;
  font-size: 1rem;
  font-weight: 400;
  color: #404049;
}

.input-default {
  width: 100%;
  padding: .5rem;
  color: #404049;
  font-size: 1rem;
  font-weight: 400;
  border: 1px solid rgba(64, 64, 73, .3);
  border-radius: 0 5px 5px 0;
}

.input-default[readonly] {
  background-color: #f0f0f0;
}

.button-default {
  width: 100%;
  padding: .5rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 5px;
  background-color: #00AF7D;
  border: 1px solid #00AF7D;
  cursor: pointer;
}

.disabled {
  background-color: #be95aea2;
  border: 1px solid #be95aea2;
  cursor: not-allowed;
}

@media screen and (max-width: 600px) {
  .container-page {
    width: 100% !important;
    padding-right: 12px;
  }
}

.card-logo {
  border: 1px solid #00AF7D;
  padding: 1rem;
  cursor: pointer;
  border-radius: 5px;
  float: left;
  transition: all .3s;
}

.card-logo:hover {
  background-color: #f0f0f081;
}
</style>
