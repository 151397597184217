<template>
    <v-app>
        <v-container fluid>
            <v-row v-if="isMobile()">
                <v-col>
                    <img src="../assets/Logo Beeasy v1 - CInza SEM FUNDO.svg" class="logo" alt="">
                </v-col>
            </v-row>
            <v-row>
                <v-col class="login">
                    <div class="card-login">
                        <v-alert
                            dense
                            type="error"
                            v-if="error"
                        >
                            {{ errorText }}
                        </v-alert>

                        <v-alert
                            dense
                            type="success"
                            v-if="success"
                        >
                            Conta criada com sucesso
                        </v-alert>
                        <img src="../assets/Logo Beeasy v1 - CInza SEM FUNDO.svg" class="logo-desk" v-if="!isMobile()" alt="">
                        <h1>Criar conta</h1>
                        <p>Preencha os dados abaixo para criar sua conta</p>
                        <div class="form-item">
                            <label>Seu nome completo</label>
                            <input type="text" class="input-default" placeholder="João da Silva" v-model="registerData.full_name">
                        </div>

                        <div class="form-item">
                            <label>Número do Whatsapp</label>
                            <input type="text" class="input-default" placeholder="(00) 00000-0000" v-mask="['(##) #####-####']" v-model="registerData.whatsapp">
                        </div>

                        <div class="form-item">
                            <label>Seu email</label>
                            <input type="email" class="input-default" placeholder="teste@gmail.com" v-model="registerData.email">
                        </div>

                        <div class="form-item">
                            <label>Senha</label>
                            <div class="form-group">
                                <input :type="hidePassword ? 'password' : 'text'" class="input-default" placeholder="••••••••••" v-model="registerData.password">
                                <img src="../assets/icons/eye.svg" width="24" height="24" @click="hidePassword = true" v-if="!hidePassword">
                                <img src="../assets/icons/close-eyes.svg" width="24" height="24" @click="hidePassword = false" v-if="hidePassword">
                            </div>
                            
                        </div>

                        <div class="form-item">
                            <button class="button-default" @click="register" v-if="!loading">Criar conta</button>
                            <button class="button-default" disabled v-if="loading">Aguarde...</button>
                        </div>
                        <div class="register">
                            <p>Já tem conta? <a href="" @click.prevent="$router.push(`/login`)">Fazer login</a></p>
                        </div>
                    </div>
                </v-col>
                <v-col class="image" v-if="!isMobile()">
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
import {mask} from 'vue-the-mask'
import axios from 'axios'
import config from '@/store/config'

const api = axios.create({
    baseURL: config.baseURL
})

export default {
    name: 'RegisterPage',
    metaInfo(){
      return{
        title: `Cadastro - Beeasy INK`
      }
    },
    directives: {
        mask
    },
    data(){
        return {
            config: {},
            error: false,
            errorText: '',
            loading: false,
            success: false,
            registerData: {
                full_name: '',
                email: '',
                password: '',
                whatsapp: ''
            },
            hidePassword: true
        }
    },
    async mounted(){
    },
    
    methods:{
        isMobile() {
            if( screen.width <= 760 ) {
                 return true;
            }else {
                return false;
            }
        },
        async validateWhatsapp(number){
            try {
                let validate = await api.get(`whatsapp/validatete/${number}`);
                return validate.data
            } catch (error) {
                return error;
            }
        },
        async register(){
            try {
                function validatePhone(phone) {
                    phone = phone.replace(/\D/g, '');
                    var regex = new RegExp('^((1[1-9])|([2-9][0-9]))((3[0-9]{3}[0-9]{4})|(9[0-9]{3}[0-9]{5}))$'); 
                    return regex.test(phone);
                }
                if(this.registerData.full_name === '' || this.registerData.email === '' || this.registerData.password === '' || this.registerData.whatsapp === ''){
                    this.$toast.warning('Preencha todos os campos')
                    return
                }

                if(!validatePhone(this.registerData.whatsapp)){
                    this.$toast.warning('Número de whatsapp inválido')
                    return
                }

                var er = new RegExp(/^[A-Za-z0-9_\-\.]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/);
                if(!er.test(this.registerData.email)){
                    this.$toast.warning('Email inválido')
                    return
                }

                this.registerData.role = 'admin_store'
                this.loading = true

                const response = await api.post('/users', this.registerData)
                if(response.data.code){
                    this.error = true
                    this.errorText = response.data.code == 'ER_DUP_ENTRY' ? 'Email já cadastrado' : 'Erro ao cadastrar'
                    this.loading = false
                    return
                }

                this.success = true
                this.$toast.success('Conta criada com sucesso. Faça login')
                this.$router.push('/login')
                this.loading = false
            } catch (error) {
                console.log(error)
            }
        }
    }
}
</script>

<style scoped>

    /* DEFAULT STYLES */
    *{
        font-family: "Outfit", sans-serif;
    }

    .input-default{
        width: 100%;
        padding: .5rem;
        color: #202E33;
        font-size: 1rem;
        font-weight: 400;
        border-radius: 5px;
        border: 1px solid #202E33;

    }

    .button-default{
        width: 100%;
        padding: .5rem;
        color: #fff;
        font-size: 1rem;
        font-weight: 500;
        border-radius: 5px;
        background-color: #202E33;
        border: 1px solid #202E33;
        cursor: pointer;
    }



    .logo{
        width: 200px;
        margin: 0 auto;
        display: block;
        margin-top: 2rem;
    }

    .logo-desk{
        width: 200px;
        float: right;
        display: block;
        margin-top: 1rem;
        margin-bottom: 1.5rem;
    }

    .image {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        background-repeat: no-repeat;
        background-size: cover;
        justify-content: space-between;
        padding-left: 40px;
        background-position: 50%;
        background-image: url("https://static.vecteezy.com/system/resources/previews/030/465/544/large_2x/clothes-hang-in-a-modern-boutique-reflecting-contemporary-fashion-at-the-clothing-shop-vertical-mobile-wallpaper-ai-generated-free-photo.jpg");
    }

    .login .card-login{
        width: 80%;
        display: block;
        margin: 0 auto;
        margin-top: 2rem;
    }

    .login .form-item{
        margin-bottom: 1rem;
    }

    .login .form-item label{
        font-size: 1rem;
        font-weight: 500;
        color: #404049;
    }

    .login .form-item button{
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    
    .form-group{
        position: relative;
    }
    .form-group img{
        position: absolute;
        right: .75rem;
        top: .55rem;
    }

    .login .register p{
        font-size: 16px;
        font-weight: 400;
        color: #000;
    }
    .login .register p a{
        font-size: 16px;
        font-weight: 500;
        color: #1c1a1d;
    }
</style>